import { MutationTree } from 'vuex';
import { AtjState } from '@/store/modules/atj/atjState';
import Vue from 'vue';
import SweepPlanning from '@/components/modules/atj/core/types/SweepPlanning';
import { AtjRoute } from '@/components/modules/atj/core/types/AtjRoute';

const mutations: MutationTree<AtjState> = {
    setSweepPlanning: (state: AtjState, payload: SweepPlanning[]) => {
        state.sweepPlanning = payload;
    },
    updateSweepPlanning: (state: AtjState, payload: SweepPlanning) => {
        const index = state.sweepPlanning.findIndex(sweepPlanning => sweepPlanning.id === payload.id);

        Vue.set(state.sweepPlanning, index, payload);
    },
    addSweepPlanning: (state: AtjState, payload: SweepPlanning) => {
        state.sweepPlanning.push(payload);
    },
    removeSweepPlanning: (state: AtjState, payload: SweepPlanning) => {
        const index = state.sweepPlanning.findIndex(sweepPlanning => sweepPlanning.id === payload.id);

        Vue.delete(state.sweepPlanning, index);
    },
    setRoutes: (state: AtjState, payload: AtjRoute[]) => {
        state.routes = payload;
    },
    setPlanningMode: (state: AtjState, payload: 'interval' | 'routes') => {
        state.planningMode = payload;
    },
    updateRoute: (state: AtjState, payload: AtjRoute) => {
        const index = state.routes.findIndex(route => route.id === payload.id);

        Vue.set(state.routes, index, payload);
    },
    addRoute: (state: AtjState, payload: AtjRoute) => {
        state.routes.push(payload);
    },
    removeRoute: (state: AtjState, payload: AtjRoute) => {
        const index = state.routes.findIndex(route => route.id === payload.id);

        Vue.delete(state.routes, index);
    },
};

export default mutations;
