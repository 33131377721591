import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { AtjState } from '@/store/modules/atj/atjState';
import SweepPlanning from '@/components/modules/atj/core/types/SweepPlanning';
import { AtjRoute } from '@/components/modules/atj/core/types/AtjRoute';

let updateSweepPlanningAbortController: AbortController | null;
let updateRouteAbortController: AbortController | null;

const actions: ActionTree<AtjState, RootState> = {
    fetchSweepPlanning: async(context: ActionContext<AtjState, RootState>) => axios.get(context.getters.atjApiUrl('/sweep-planning'))
        .then(response => context.commit('setSweepPlanning', response.data)),

    fetchRoutes: async(context: ActionContext<AtjState, RootState>) => axios.get(context.getters.atjApiUrl('/routes'))
        .then(response => context.commit('setRoutes', response.data)),

    fetchPlanningMode: async(context: ActionContext<AtjState, RootState>) => axios.get(context.getters.atjApiUrl('/settings'))
        .then(response => context.commit('setPlanningMode', response.data.planning_mode)),

    updatePlanningMode: async(context: ActionContext<AtjState, RootState>, payload: 'routes' | 'interval') => axios
        .put(context.getters.atjApiUrl('/settings'), {
            planning_mode: payload,
        }).then(response => context.commit('setPlanningMode', response.data.planning_mode)),

    removeSweepPlanning: async(context: ActionContext<AtjState, RootState>, payload: SweepPlanning) => {
        context.commit('removeSweepPlanning', payload);

        axios
            .delete(context.getters.atjApiUrl(`/sweep-planning/${payload.id}`))
            .then(() => context.dispatch('fetchSweepPlanning'));
    },

    addSweepPlanning: async(context: ActionContext<AtjState, RootState>, payload: Partial<SweepPlanning>) => {
        context.commit('addSweepPlanning', payload);

        axios
            .post(context.getters.atjApiUrl('/sweep-planning'), payload)
            .then(() => context.dispatch('fetchSweepPlanning'));
    },

    updateSweepPlanning: async(context: ActionContext<AtjState, RootState>, payload: SweepPlanning) => {
        context.commit('updateSweepPlanning', payload);

        if (updateSweepPlanningAbortController) {
            updateSweepPlanningAbortController.abort();
        }

        updateSweepPlanningAbortController = new AbortController();

        axios
            .put(context.getters.atjApiUrl(`/sweep-planning/${payload.id}`), payload, {
                signal: updateSweepPlanningAbortController.signal,
            })
            .then(() => context.dispatch('fetchSweepPlanning'));
    },

    removeRoute: async(context: ActionContext<AtjState, RootState>, payload: AtjRoute) => {
        context.commit('removeRoute', payload);

        axios
            .delete(context.getters.atjApiUrl(`/routes/${payload.id}`))
            .then(() => context.dispatch('fetchRoutes'));
    },

    addRoute: async(context: ActionContext<AtjState, RootState>, payload: Partial<AtjRoute>) => {
        context.commit('addRoute', payload);

        axios
            .post(context.getters.atjApiUrl('/routes'), payload)
            .then(() => context.dispatch('fetchRoutes'));
    },

    updateRoute: async(context: ActionContext<AtjState, RootState>, payload: AtjRoute) => {
        context.commit('updateRoute', payload);

        if (updateRouteAbortController) {
            updateRouteAbortController.abort();
        }

        updateRouteAbortController = new AbortController();

        axios
            .put(context.getters.atjApiUrl(`/routes/${payload.id}`), payload, {
                signal: updateRouteAbortController.signal,
            })
            .then(() => context.dispatch('fetchRoutes'));
    },
};

export default actions;
