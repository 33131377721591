import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { AtjState } from '@/store/modules/atj/atjState';

const getters: GetterTree<AtjState, RootState> = {
    atjApiUrl: (state: AtjState, getters: any, rootState: RootState) => (url: string): string => `/atj/farms/${rootState.farmId}${url}`,

    sweepPlanningSortedByTime: (state: AtjState) => state
        .sweepPlanning
        .sort((a, b) => Number(a.time.replace(':', ''))
            - Number(b.time.replace(':', ''))),

    routes: (state: AtjState) => state.routes,
};
export default getters;
